import configs from "../config";
import { BODY_TYPE, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../utils/api";
import { attachHeader } from "../utils/apiHandler";

export const getDomainList = () => {
    const request = {
      url: configs.API_URL_V2 + 'master/domain/',
      method: METHOD_TYPE.GET,
      apiOptions: {
        headers: attachHeader(),
        withCredentials: true
      }
    };
    return commonApi(request);
};

export const getSpeciality = (domainId) => {
  const request = {
    url: configs.API_URL_V2 + 'master/speciality/' + domainId + '/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getLanguagesList = () => {
  const request = {
      url: configs.API_URL_V2 + 'languages/',
      method: METHOD_TYPE.GET,
      apiOptions: {
        headers: attachHeader(),
        withCredentials: true
      }
  };
  return commonApi(request);
};

export const getServiceList = (speciality) => {
  const request = {
      url: configs.API_URL_V2 + 'services/',
      method: METHOD_TYPE.POST,
      body: {
          type: BODY_TYPE.RAW,
          data: {
              speciality: speciality
          }
      },
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }

  };
  return commonApi(request);
};

export const getQuestionsList = () => {
  const request = {
      url: configs.API_URL_V2 + 'questions/',
      method: METHOD_TYPE.POST,
      body: {
          type: BODY_TYPE.RAW,
          data: {
              modelType: "doctor"
          }
      },
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }
  };
  return commonApi(request);
};

export const getClinicList = (searchquery) => {
  const request = {
      url: configs.API_URL + 'searchclinic/?q='+searchquery,
      method: METHOD_TYPE.GET,
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }
  };
  return commonApi(request);
};

export const checkActivationKey = (body) => {
  const request = {
      url: configs.API_URL + 'checkactivationkey/',
      method: METHOD_TYPE.POST,
      body: {
        type: BODY_TYPE.RAW,
        data: body
      },
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }
  };
  return commonApi(request);
};

export const validateLicense = (body) => {
  const request = {
      url: configs.API_URL + 'validatelicence/',
      method: METHOD_TYPE.POST,
      body: {
        type: BODY_TYPE.RAW,
        data: body
      },
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }
  };
  return commonApi(request);
};

export const validateLogin = (body) => {
  const request = {
      url: configs.API_URL + 'validatelogin/',
      method: METHOD_TYPE.POST,
      body: {
        type: BODY_TYPE.RAW,
        data: body
      },
      apiOptions: {
          headers: attachHeader(),
          withCredentials: true
      }
  };
  return commonApi(request);
};

export const getFeatures = (speciality) => {
  const request = {
    url: configs.API_URL_V2 + 'master/features/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        speciality: speciality
      }
    },
    apiOptions: {
      headers: attachHeader(),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const sendActivationKey = (phone, email) => {
  const request = {
    url: configs.API_URL + 'sendkey/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        phone: phone,
        email: email
      }
    },
    apiOptions: {
      headers: attachHeader(),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const createNewClinic = (data) => {
  const request = {
    url: configs.API_URL + 'newclinic/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const createNewProvider = (data) => {
  const request = {
    url: configs.API_URL + 'saveprovider/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};