import AppStoreBadge from "../../images/app-store-badge.svg";
import PlayStoreBadge from "../../images/play-store-badge.svg";
export const Thankyou = () => {
    return (
        <section class="full-height">
        <div class="container h-100">
            <div class="row justify-content-center align-items-center h-100">
                <div class="col-12 col-md-6 text-center">
                    <div class="title mb-3">Thank you</div>
                    <p class="txt-content mx-auto">You will receive an activation email and please download mobile
                        provider app and sign in.</p>
                    <div class="d-flex download-links justify-content-center">
                        <a href="https://play.google.com/store/apps/details?id=com.xcarepro.android" target={"_blank"} class="d-block m-3">
                            <img src={PlayStoreBadge} alt=""/>
                        </a>
                        <a href="https://apps.apple.com/us/app/x-care/id1517457992?ls=1" target={"_blank"} class="d-block m-3">
                            <img src={AppStoreBadge} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}