import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { checkActivationKey, getLanguagesList, getSpeciality, validateLicense, validateLogin } from "../../services";
import { updateRequest } from "../../store/slices/provider";
import { emailValidator, passwordValidator } from "../../utils/validators";

export const Step2 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const uploadRef = useRef();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            previewFile: providerRequest.previewFile,
            logo: providerRequest.logo,
            fullname: providerRequest.fullname,
            email: providerRequest.email,
            phonenumber: providerRequest?.phonenumber,
            password: '',
            confirmPassword: '',
            gender: 'Male',
            languages: providerRequest.languages,
            speciality: providerRequest.speciality,
            license: providerRequest.license,
            activationKey: providerRequest?.activationKey
        },
        validate: async (values) => {
            const errors = {};
            // if (values.previewFile === '') {
            //     errors.logo = 'Logo is required';
            // }
            if (!values?.fullname || values.fullname?.trim() === '') {
                errors.fullname = "Provider's full name is required";
            }
            if (values.email?.trim() === '') {
                errors.email = 'Email is required';
            }
            if (!emailValidator(values.email)) {
                errors.email = 'Please enter valid email';
            }
            if (values.phonenumber?.trim() === '') {
                errors.phonenumber = 'Mobile number is required';
            }
            if (isNaN(values.phonenumber)) {
                errors.phonenumber = 'Mobile number should be in digit';
            }
            if (values.password === '') {
                errors.password = 'Password is required';
            }
            if (!passwordValidator(values.password)) {
                errors.password = 'Password must have at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters.';
            }
            if (values.confirmPassword === '') {
                errors.confirmPassword = 'Confirm password is required';
            }
            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Confirm password should be same as password';
            }
            if (values.languages === '') {
                errors.languages = 'Languages is required';
            }
            if (!values?.speciality || values.speciality === '') {
                errors.speciality = 'Speciality is required';
            }
            if (!values?.license || values.license === '') {
                errors.license = 'License is required';
            }
            if (values.activationKey === '') {
                errors.activationKey = 'Activation key is required';
            }
            return errors;
        },
        onSubmit: async (values) => {

            if (values.license !== '') {
                try {
                    await validateLicense({
                        "licence": values.license,
                    });
                } catch (error) {
                    if (error?.error === true)  {
                        formik.setFieldError('license', error?.msg);
                        return;
                    }
                }
            }
            
            if (values.email !== '' && values.phonenumber !== '') {
                try {
                    await validateLogin({
                        "email": values.email,
                        "phone": values.phonenumber,
                    });
                } catch (error) {
                    if (error?.error === true)  {
                        formik.setFieldError('email', error?.msg);
                        return;
                    }
                }
            }
            dispatch(updateRequest({
                ...providerRequest,
                fullname: values.fullname,
                email: values.email,
                password: values.password,
                confirmPassword: values.confirmPassword,
                gender: values.gender,
                license: values.license,
            }));
            navigate('/step3');
        },
    });
    const [specialties, setSpecialities] = useState([]);
    const [languages, setLanguages] = useState([]);
    const selectedPracticeType = providerRequest?.practiceType?.id;
    const selectedSpecilaties = specialties?.filter((item) => {
        if (providerRequest?.speciality?.includes(item.value)) {
            return true;
        }
        return false;
    });
    const selectedLaunguage = languages?.filter((item) => {
        if (providerRequest?.languages?.split(",")?.includes(item.value)) {
            return true;
        }
        return false;
    });
    useEffect(() => {
        getSpeciality(selectedPracticeType).then((success) => {
            if (success.status.error === false) {
                setSpecialities(success.specialities.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }));
            }
        }).catch((error) => {
            console.log("getSpeciality: error", error);
        });
        getLanguagesList().then((success) => {
            if (success.status.error === false) {
                setLanguages(success.languages.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }));
            }
        }).catch((err) => {
            console.log("getLanguagesList: err", err);
        });
    }, []);

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/png" ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg')) {
            formik.setFieldValue('logo', event.target.files[0]);
            var reader = new FileReader();
            var url = reader.readAsDataURL(event.target.files[0]);
            reader.onloadend = function (e) {
                //Initiate the JavaScript Image object.
                var image = new Image();
                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;
                //Validate the File Height and Width.
                image.onload = function () {
                    var height = image.height;
                    var width = image.width;
                    if (height < 256 || width < 256) {
                        formik.setFieldError('logo', 'Please upload valid file');
                        return false;
                    }
                    formik.setFieldValue('previewFile', reader.result);
                    dispatch(updateRequest({
                        ...providerRequest,
                        previewFile: reader.result,
                        logo: {
                            type: file.type,
                            name: file.name
                        }
                    }));
                    return true;
                };
            };
            return;
        } else {
            formik.setFieldError('logo', 'Please upload file');
        }
    }
    return (
        <section class="practice-step-sec">
            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "28%" }}> <span
                    class="fw-medium text-primary progper">28%</span></div>
            </div>
            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">
                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>
                            </li>
                            <li class="step-list active">
                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Personal info</h2>
                                    <div class="steps-subtitle">Personal information of provider</div>
                                    <div class="steps-desc">Please ensure that all of the following information is correct. We will use this
                                        profile for your listing and for marketing purposes.</div>
                                </div>

                                <div class="step-content">
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="profile-logo-sec d-flex">
                                                <div class="img-box rounded-circle border position-relative">
                                                    {
                                                        formik.values.previewFile && formik.values.previewFile !== '' &&
                                                        <button class="btn text-white position-absolute top-0 end-0 p-0 rounded-circle" onClick={() => {
                                                            formik.setFieldValue('logo', '');
                                                            formik.setFieldValue('previewFile', '');
                                                            uploadRef.current.value = '';
                                                        }}>
                                                            <i class="bi bi-x"></i>
                                                        </button>
                                                    }
                                                    <img className="rounded-circle" style={{ height: 120, width: 120 }} src={formik.values.previewFile}></img>
                                                </div>
                                                <div class="txt-box align-self-center ms-2 ms-md-4 m">
                                                    <input ref={uploadRef} type="file" style={{ "display": "none" }} onChange={onFileChange}></input>
                                                    <button class="btn border d-block fw-normal" onClick={(e) => {
                                                        uploadRef.current.click();
                                                    }}>Upload profile picture</button>
                                                    <span class="d-block mt-2 text-muted">At least 256 x 256px PNG or JPG file.</span>
                                                    {
                                                        formik.errors.logo &&
                                                        <div class="text-danger">{formik.errors.logo}</div>
                                                    }
                                                    

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-sm-6 mb-4">
                                                    <label for="Specialties" class="form-label">Specialties</label>
                                                    <Select className="select2 myslt" options={specialties} isMulti
                                                        value={selectedSpecilaties}
                                                        onChange={(e) => {
                                                            formik.setFieldValue('speciality', e.map((item) => item.value).toString());
                                                            dispatch(updateRequest({
                                                                ...providerRequest,
                                                                speciality: e.map((item) => item.value).toString()
                                                            }));
                                                        }}
                                                    ></Select>
                                                    {/* <input type="text" class="form-control" id="practicetName" name="Specialties" placeholder="" /> */}
                                                    <small class="text-muted">Ex. Dentist, Orthodontist, Hygienist, etc...</small>

                                                    {
                                                        formik.errors.speciality &&
                                                        <div class="text-danger">{formik.errors.speciality}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="provider-name" class="form-label">Provider full name</label>
                                                    <input type="text" class="form-control" id="practicetName" name="fullname" value={formik.values.fullname} onChange={formik.handleChange} placeholder="" />
                                                    {
                                                        formik.errors.fullname &&
                                                        <div class="text-danger">{formik.errors.fullname}</div>
                                                    }
                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="emailAddress" class="form-label">Email address</label>
                                                    <input disabled type="email" class="form-control" id="emailAddress" name="email" value={formik.values.email} onChange={formik.handleChange} placeholder="xyz@provider.com" />
                                                    <small class="text-muted">We'll never share your email with anyone else.</small>

                                                    {
                                                        formik.errors.email &&
                                                        <div class="text-danger">{formik.errors.email}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="phoneNumber" class="form-label">Mobile number</label>
                                                    <input disabled type="text" class="form-control" name="phonenumber" value={formik.values.phonenumber} onChange={formik.handleChange} />

                                                    {
                                                        formik.errors.phonenumber &&
                                                        <div class="text-danger">{formik.errors.phonenumber}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="password" class="form-label">Password</label>
                                                    <input type="password" class="form-control" id="password" name="password" value={formik.values.password} onChange={formik.handleChange} placeholder="" />

                                                    {
                                                        formik.errors.password &&
                                                        <div class="text-danger">{formik.errors.password}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="confirmpassword" class="form-label">Confirm Password</label>
                                                    <input type="password" class="form-control" id="confirmpassword" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} placeholder="" />

                                                    {
                                                        formik.errors.confirmPassword &&
                                                        <div class="text-danger">{formik.errors.confirmPassword}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">

                                                    <label class="form-label d-block">Gender</label>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="male" value="Male" checked={formik.values.gender === 'Male'} onClick={()=> {
                                                            formik.setFieldValue('gender', 'Male')
                                                        }}/>
                                                        <label class="form-check-label" for="male">Male</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="female" value="Female" checked={formik.values.gender === 'Female'} onClick={()=> {
                                                            formik.setFieldValue('gender', 'Female')
                                                        }}/>
                                                        <label class="form-check-label" for="female">Female</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="other" value="Other" checked={formik.values.gender === 'Other'} onClick={()=> {
                                                            formik.setFieldValue('gender', 'Other')
                                                        }}/>
                                                        <label class="form-check-label" for="other">Other</label>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="language-spoken" class="form-label">Language Spoken</label>
                                                    <Select className="select2 myslt" options={languages} isMulti
                                                        value={selectedLaunguage}
                                                        onChange={(e) => {
                                                            formik.setFieldValue('languages', e.map((item) => item.value).toString())
                                                            dispatch(updateRequest({
                                                                ...providerRequest,
                                                                languages: e.map((item) => item.value).toString()
                                                            }));
                                                        }}
                                                    ></Select>

                                                    {
                                                        formik.errors.languages &&
                                                        <div class="text-danger">{formik.errors.languages}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="LicenceNumber" class="form-label">Licence Number</label>
                                                    <input type="text" class="form-control" id="LicenceNumber" name="license" value={formik.values.license} onChange={formik.handleChange} placeholder="" />

                                                    {
                                                        formik.errors.license &&
                                                        <div class="text-danger">{formik.errors.license}</div>
                                                    }

                                                </div>

                                                <div class="col-sm-6 mb-4">
                                                    <label for="Activationkey" class="form-label">Activation key</label>
                                                    <input disabled type="text" class="form-control" id="Activationkey" name="activationKey" value={formik.values.activationKey} onChange={formik.handleChange} placeholder=""/>

                                                    {
                                                        formik.errors.activationKey &&
                                                        <div class="text-danger">{formik.errors.activationKey}</div>
                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                    navigate('/');
                                }}>Back</button>
                                <button type="submit" class="btn btn-primary ms-1" onClick={formik.handleSubmit}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}