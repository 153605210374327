import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from "../../store/slices/provider";
import { createNewProvider, getClinicList } from "../../services";
import React, { useMemo, useState } from "react";
import AsyncSelect from 'react-select/async';
import { TimePicker } from '@mui/x-date-pickers';
import configs from "../../config";
import { showAddress } from "../../components/renderAddress";
import moment from "moment";
import { dataURLtoFile } from "../../utils";
const tempHours = [{
    id: 'MON',
    active: false,
    label: 'Monday',
    startHour: '07:00 AM',
    endHour: '07:00 PM',
},
{
    id: 'TUE',
    active: false,
    label: 'Tuesday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
},
{
    id: 'WED',
    active: false,
    label: 'Wednesday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
},
{
    id: 'THU',
    active: false,
    label: 'Thursday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
},
{
    id: 'FRI',
    active: false,
    label: 'Friday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
},
{
    id: 'SAT',
    active: false,
    label: 'Saturday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
},
{
    id: 'SUN',
    active: false,
    label: 'Sunday',
    startHour: '07:00 AM',
    endHour: '07:00 PM'
}];
export const Step7 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [selectedClinic, setSelectedClinic] = useState();
    const [clinicOptions, setClinicOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const promiseOptions = (inputValue) => {
        return new Promise(async (resolve) => {
            const response = await getClinicList(inputValue);
            resolve(response.clinic?.map((item) => ({ ...item, label: item.name, value: item.uniqueId })));
            setClinicOptions(response.clinic?.map((item) => ({
                ...item, label: item.name, value: item.uniqueId,
                hours: item.hours?.map((item) => ({
                    ...item,
                    startHourVal: item.startHour,
                    endHourVal: item.endHour,
                    clinicClose: item.startHour === 'Closed'
                }))
            })));
        });
    }
    const handleFinalSave = () => {
        setLoading(true);
        const obj = {};
        obj.domain = providerRequest?.practiceType?.id;
        obj.licence = providerRequest?.license;
        obj.logo = providerRequest?.logo?.name && providerRequest?.previewFile ? dataURLtoFile(providerRequest?.previewFile, providerRequest?.logo?.name) : undefined;
        obj.email = providerRequest?.email;
        obj.fullname = providerRequest?.fullname;
        obj.phone = providerRequest?.phonenumber;
        obj.website = providerRequest?.email;
        if (!providerRequest?.openToWork) {
            providerRequest.selectedClinics?.forEach((item, index) => {
                obj[`clinicUniqueid[${index}]`] = item.uniqueId;
            });
        }
        providerRequest.selectedClinics?.forEach((item, index) => {
            obj[`hours[${index}]`] = JSON.stringify(item?.hours?.map((item) => {
                if (item.clinicClose) {
                    return {
                        ...item,
                        startHour: 'Closed',
                        endHour: 'Closed'
                    }
                } else {
                    return item;
                }
            }));
        });
        providerRequest?.uploadedPhotos?.forEach((item, index) => {
            obj[`uphoto[${index}]`] = new File([item.url], item?.file?.path);
        });
        obj.description = providerRequest.description;
        obj.questionResponse = JSON.stringify(providerRequest.questionResponse);
        obj.questionResponse = JSON.stringify(providerRequest.questionResponse);
        obj.metaTitle = providerRequest?.metaTitle;
        obj.metaDesc = providerRequest?.metaDesc;
        obj.metaKeywords = providerRequest?.metaKeywords ? providerRequest?.metaKeywords.map((item) => item.label).toString() : '';
        obj.speciality = providerRequest.speciality;
        obj.services = providerRequest.services;
        obj.educations = JSON.stringify(providerRequest.educationDetailList);
        obj.certificates = JSON.stringify(providerRequest.certificationList);
        obj.gender = providerRequest.gender;
        obj.languages = providerRequest?.languages;
        obj.openToWork = providerRequest?.openToWork;
        createNewProvider(obj).then((success) => {
            setLoading(false);
            if (success.status.error === false) {
                navigate('/thankyou')
            } else {
                alert("Failed to save data");
            }
        }).catch((error) => {
            setLoading(false);
            alert("Failed to save data");
        })
    }
    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "90%" }}> <span
                    class="fw-medium text-primary progper">90%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Link your account with the practice/clinic</h2>
                                    <div class="steps-subtitle">Its time to get you up and running</div>
                                    <div class="steps-desc">If you don't see your practice listed in the list below, please click on “Don't
                                        see my practice/clinic” link below.</div>
                                </div>

                                <div class="step-content">

                                    <div class="clinic-search">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="Clinic ID/Name" class="form-label">Clinic ID/Name</label>
                                            </div>
                                            <div class="col-5">
                                                <AsyncSelect isDisabled={providerRequest?.openToWork} inputValue={inputValue} onInputChange={(value) => {
                                                    setInputValue(value);
                                                }} cacheOptions defaultOptions={clinicOptions} loadOptions={promiseOptions} onChange={(value) => {
                                                    setSelectedClinic(value);
                                                }} />
                                            </div>
                                            <div class="col-auto">
                                                <button type="submit" disabled={!selectedClinic || providerRequest?.openToWork} class="btn btn-primary mb-3" onClick={() => {
                                                    const hoursClinic = selectedClinic?.hours ?? [];
                                                    dispatch(updateRequest({
                                                        ...providerRequest,
                                                        selectedClinics: [...providerRequest?.selectedClinics, {
                                                            ...selectedClinic,
                                                            selectedDays: [],
                                                            hours: hoursClinic.map((item, index) => {
                                                                if (hoursClinic[index].startHour === 'Closed') {
                                                                    return {
                                                                        ...item,
                                                                        startHourVal: item.startHour,
                                                                        endHourVal: item.endHour,
                                                                        active: false,
                                                                        clinicClose: true,
                                                                        startHour: 'Closed',
                                                                        endHour: 'Closed'
                                                                    }
                                                                } else if (hoursClinic[index].startHour !== 'Closed') {
                                                                    return {
                                                                        ...item,
                                                                        startHourVal: item.startHour,
                                                                        endHourVal: item.endHour,
                                                                        active: false,
                                                                        clinicClose: false,
                                                                        startHour: hoursClinic[index].startHour,
                                                                        endHour: hoursClinic[index].endHour
                                                                    }
                                                                }
                                                            })
                                                        }]
                                                    }));
                                                    setSelectedClinic(null);
                                                    setInputValue('');
                                                }}>Add clinic</button>
                                            </div>
                                        </div>

                                        <NavLink to="/clinic/step1" >Don't see your clinic/practice</NavLink>

                                        <div class="mt-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="associatedclinic" checked={providerRequest?.openToWork} onChange={() => {
                                                    let obj = {
                                                        ...providerRequest,
                                                        openToWork: !providerRequest?.openToWork,
                                                        selectedClinics: []
                                                    };
                                                    if (!providerRequest?.openToWork) {
                                                        obj.selectedClinics = [{
                                                            uniqueId: 123,
                                                            hours: tempHours,
                                                            selectedDays: []
                                                        }];
                                                    } else {
                                                        obj.selectedClinics = [];
                                                    }
                                                    dispatch(updateRequest(obj));
                                                }} />
                                                <label class="form-check-label" for="associatedclinic">
                                                    I am not associated with any clinic, I am open to work
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    {
                                        providerRequest?.selectedClinics?.selectedClinics > 0 && !providerRequest?.openToWork &&
                                        <div class="steps-title-section">
                                            <h2 class="steps-title">Availablity</h2>
                                            <div class="steps-subtitle">When does {providerRequest?.fullname} work at Admin Clinic template check?</div>
                                            <div class="steps-desc">Please set the office hours to let patients know when you're open.</div>
                                        </div>
                                    }

                                    {
                                        providerRequest?.openToWork &&
                                        <>
                                            <div class="steps-title-section">
                                                <h2 class="steps-title">Open to work</h2>
                                                <div class="steps-subtitle">Open to work on any specific days</div>
                                                <div class="steps-desc">If you don't see your practice listed in the list below, please click on “Don't see my practice/clinic” link below.</div>
                                            </div>
                                        </>
                                    }

                                    {
                                        useMemo(() => {
                                            return (
                                                <>
                                                    {
                                                        providerRequest?.selectedClinics?.map((item) => {
                                                            return (
                                                                <React.Fragment key={item.uniqueId}>
                                                                    <ManageClinicHours clinic={item}></ManageClinicHours>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        }, [providerRequest?.selectedClinics])
                                    }

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" disabled={loading} onClick={() => {
                                    navigate('/step6')
                                }}>Back</button>
                                <button disabled={providerRequest?.selectedClinics?.length === 0 || loading} class="btn btn-primary ms-1" onClick={() => {
                                    handleFinalSave();
                                }}>{loading ? 'Loading...' : 'Next'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
let toCloseTimePicker = false;
const ManageClinicHours = (props) => {
    const dispatch = useDispatch();
    const providerRequest = useSelector((state) => state.provider);
    const Days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const { clinic } = props;
    const selectedDays = clinic?.selectedDays;
    const hours = clinic?.hours;
    const setSelectedDays = (value, hours) => {
        dispatch(updateRequest({
            ...providerRequest,
            selectedClinics: providerRequest?.selectedClinics?.map((item) => {
                if (item.value === clinic.value) {
                    return {
                        ...item,
                        selectedDays: value,
                        hours: hours
                    }
                }
                return item;
            })
        }))
    }
    const setHours = (value) => {
        dispatch(updateRequest({
            ...providerRequest,
            selectedClinics: providerRequest?.selectedClinics?.map((item) => {
                if (item.value === clinic.value) {
                    return {
                        ...item,
                        hours: value
                    }
                }
                return item;
            })
        }))
    }
    const handleDateChange = (date, hIndex, index) => {
        if (hIndex === 0) {
            let selectedDay = hours.find((item, hIndex) => hIndex === index);
            let clinicHour = clinic?.hours?.find((item) => item.id === selectedDay.id);
            if (moment(clinicHour.startHourVal, 'LT').isAfter(moment(moment(date).format('LT'), 'LT'))) {
                alert('Start time should be next to clinic start time.');
                return;
            } else if (moment(clinicHour.endHourVal, 'LT').isBefore(moment(moment(date).format('LT'), 'LT'))) {
                alert('Clinic is being closed at ' + clinicHour.endHourVal);
                return;
            }
            const mappedHours = hours.map((hItem, hIndex) => {
                if (hIndex === index) {
                    toCloseTimePicker = true;
                    return {
                        ...hItem,
                        openStart: false,
                        startHour: moment(date).format("LT"),
                        startShow: date,
                        endHour: moment(date).format("LT"),
                        endShow: date
                    }
                }
                return hItem;
            });
            setHours(mappedHours);
        } else {
            let selectedDay = hours.find((item, hIndex) => hIndex === index);
            let clinicHour = clinic?.hours?.find((item) => item.id === selectedDay.id);
            if (moment(selectedDay.startHour, 'LT').isAfter(moment(moment(date).format('LT'), 'LT'))) {
                alert('End time shoud be next to start time');
                return;
            } else if (moment(clinicHour.endHourVal, 'LT').isBefore(moment(moment(date).format('LT'), 'LT'))) {
                alert('Clinic is being closed at ' + clinicHour.endHourVal);
                return;
            }
            const mappedHours = hours.map((hItem, hIndex) => {
                if (hIndex === index) {
                    toCloseTimePicker = true;
                    return {
                        ...hItem,
                        openEnd: false,
                        endHour: moment(date).format("LT"),
                        endShow: date,
                    }
                }
                return hItem;
            });
            setHours(mappedHours);
        }
    };
    return (
        <div class="clinic-search-results">
            {
                !providerRequest?.openToWork &&
                <div className="d-flex justify-content-between align-items-center">
                    <div class="avatar">
                        <div class="avatar-image">
                            <img src={configs.IMAGE_URL + "clinic" + "/" + clinic?.logo?.name + "-300x300." + clinic?.logo?.type} alt="" />
                        </div>
                        <div class="avatar-content">
                            <div class="avatar-title">
                                {clinic?.name}
                            </div>
                            <div class="avatar-sub-title">
                                {showAddress(clinic.address)}
                            </div>
                        </div>

                    </div>
                    <div className="">
                        <i class="bi bi-trash" onClick={() => {
                            dispatch(updateRequest({
                                selectedClinics: providerRequest?.selectedClinics?.filter((item) => item.value !== clinic.value)
                            }));
                        }}></i>
                    </div>
                </div>
            }
            <div class="col-12">
                <ul class="d-flex days-checklist">
                    {
                        Days.map((item) => {
                            return (
                                <li className="form-check" key={item}>
                                    <input className="form-check-input" type="checkbox" value={item + + clinic.value}
                                        id={item + clinic.value} checked={selectedDays.find((day) => day === item) !== undefined}
                                        onChange={(e) => {
                                            const dayIndex = Days.findIndex((day) => day === item);
                                            if (selectedDays.find((day) => day === item) !== undefined) {
                                                setSelectedDays(selectedDays.filter((day) => day !== item), hours.map((item, index) => {
                                                    if (dayIndex === index) {
                                                        const hoursClinic = clinic?.hours ?? [];
                                                        if (hoursClinic[index].startHour === 'Closed') {
                                                            return {
                                                                ...item,
                                                                active: false,
                                                                clinicClose: true,
                                                                startHour: 'Closed',
                                                                endHour: 'Closed',
                                                                isShow: false
                                                            }
                                                        }
                                                        else if (hoursClinic[index].startHour !== 'Closed') {
                                                            return {
                                                                ...item,
                                                                clinicClose: false,
                                                                startHour: hoursClinic[index].startHour,
                                                                endHour: hoursClinic[index].endHour,
                                                                isShow: false,
                                                                active: false
                                                            }
                                                        }
                                                    }
                                                    return item;
                                                }));
                                            } else {
                                                setSelectedDays([...selectedDays, item], hours.map((item, index) => {
                                                    if (dayIndex === index) {
                                                        const hoursClinic = clinic?.hours ?? [];
                                                        if (hoursClinic[index].startHour === 'Closed') {
                                                            return {
                                                                ...item,
                                                                active: true,
                                                                clinicClose: true,
                                                                startHour: 'Closed',
                                                                endHour: 'Closed',
                                                                isShow: true
                                                            }
                                                        } else if (hoursClinic[index].startHour !== 'Closed') {
                                                            return {
                                                                ...item,
                                                                active: true,
                                                                clinicClose: false,
                                                                startHour: hoursClinic[index].startHour,
                                                                endHour: hoursClinic[index].endHour,
                                                                isShow: true
                                                            }
                                                        }
                                                    }
                                                    return item;
                                                }));
                                            }
                                        }}
                                    />
                                    <label className="form-check-label rounded-2 border w-100" htmlFor={item}>
                                        {item}
                                    </label>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div class="col-12">
                <form class="open-hours-info">
                    {
                        hours.map((hour, index) => {
                            if (hour.active === true || hour.isShow === true) {
                                return (
                                    <div className="d-flex hours-box my-3" key={index + hour.id}>
                                        <label className="day-title fw-normal align-self-center">{hour.label}</label>
                                        <div className="input-group flex-nowrap">
                                            <input type="text" className="form-control border-end-0" placeholder="Open"
                                                aria-label="Recipient's username" aria-describedby="basic-addon2" value={hour.startHour} disabled />
                                            <span className="input-group-text bg-white" id="basic-addon2">
                                                {
                                                    hour.startHour !== 'Closed' && !hour.clinicClose &&
                                                    <i className="bi bi-x me-2 cursor-pointer" onClick={() => {
                                                        if (hour.clinicClose) {
                                                            return;
                                                        }
                                                        setHours(hours.map((hItem, hIndex) => {
                                                            if (index === hIndex) {
                                                                return {
                                                                    ...hItem,
                                                                    startHour: 'Closed',
                                                                    endHour: 'Closed',
                                                                }
                                                            }
                                                            return hItem;
                                                        }));
                                                    }}></i>
                                                }
                                                {
                                                    !hour.clinicClose &&
                                                    <TimePicker
                                                        margin="normal"
                                                        id="time-picker"
                                                        label="Time picker"
                                                        value={hour.startShow && moment(hour.startShow).isValid() ? moment(hour.startShow) : undefined}
                                                        onChange={(date) => { handleDateChange(date, 0, index) }}
                                                    />
                                                }
                                            </span>
                                        </div>
                                        <div className="mx-4 align-self-center text-muted">To</div>
                                        <div className="input-group flex-nowrap">
                                            <input type="text" className="form-control border-end-0" placeholder="Closed" value={hour.endHour} disabled />
                                            <span className="input-group-text bg-white">
                                                {
                                                    hour.endHour !== 'Closed' && !hour.clinicClose &&
                                                    <i className="bi bi-x me-2 cursor-pointer" onClick={() => {
                                                        if (hour.clinicClose) {
                                                            return;
                                                        }
                                                        setHours(hours.map((hItem, hIndex) => {
                                                            if (index === hIndex) {
                                                                return {
                                                                    ...hItem,
                                                                    startHour: 'Closed',
                                                                    endHour: 'Closed',
                                                                }
                                                            }
                                                            return hItem;
                                                        }));
                                                    }}></i>
                                                }
                                                {
                                                    !hour.clinicClose &&
                                                    <TimePicker
                                                        margin="normal"
                                                        id="time-picker"
                                                        label="Time picker"
                                                        value={hour.endShow && moment(hour.endShow).isValid() ? moment(hour.endShow) : undefined}
                                                        onChange={(date) => { handleDateChange(date, 1, index) }}
                                                    />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                            return null;
                        })
                    }
                </form>
            </div>
        </div>
    )
}