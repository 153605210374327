import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import configs from "../../config";
import { updateRequest } from "../../store/slices/provider";
export const Step6 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png, image/jpg',
		multiple: false,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles[0] && acceptedFiles[0]?.type?.includes("image")) {
                dispatch(updateRequest({
                    ...providerRequest,
                    uploadedPhotos: [...providerRequest.uploadedPhotos, {
                        url: URL.createObjectURL(acceptedFiles[0]), file: acceptedFiles[0]
                    }]
                }));
			}
		}
	});
    const onDeleteImage = (index) => {
        const temp = providerRequest?.uploadedPhotos?.filter((item, _index) => index !== _index);
        dispatch(updateRequest({
            ...providerRequest,
            uploadedPhotos: temp
        }));
	}
    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "84%" }}> <span
                    class="fw-medium text-primary progper">84%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Photos</h2>
                                    <div class="steps-subtitle">Provider Photos</div>
                                    <div class="steps-desc">Uploading your pictures helps boost your profile on Xcarepro. We recommend uploading at least 3-5
                                        photos.</div>
                                </div>

                                <div class="step-content">
                                    <div class="row mb-4">
                                        <div class="col-12">
                                            <div class="img-gallery-practice d-flex">
                                                {
                                                    providerRequest?.uploadedPhotos?.map((item, index) => {
                                                        return (
                                                            <div className="img-box d-inline-block" key={index + ""}>
                                                                <button className="btn p-0 position-absolute delete-img-btn" onClick={(e) => {
                                                                    onDeleteImage(index);
                                                                }}>
                                                                    <i class="bi bi-trash"></i>
                                                                </button>
                                                                <img src={item?.url} height={115} width={115} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-12">
                                            <div class="border rounded upload-sec p-4"><label class="form-label">Upload</label>
                                                <section class="container upload-input-box py-5 text-center mb-4">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <em>(Only *.jpeg, *.jpg and *.png images will be accepted)</em>
                                                        <div><button className="btn btn-link ps-0 py-0">Browse</button></div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                        navigate('/step5');
                                    }}>Back</button>
                                <button class="btn btn-primary ms-1" onClick={() => {
                                        navigate('/step7');
                                    }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}