/**
 * 
 * @param isMultiPart true if request is multipart/form-data : default to false
 */
export const attachHeader = (isMultiPart = false) => {
    try {
        
        let headers = null;
        if (isMultiPart) {
            headers = {
                'Content-Type': 'multipart/form-data',
                'xprApp': 'provider'
            }
        } else {
            headers = {
                'Content-Type': 'application/json',
                'xprApp': 'provider'
            }
        }
        return headers;
    } catch (error) {
        window.location.href = '/';
    }
}