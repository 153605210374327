import { createSlice } from '@reduxjs/toolkit'
import RichTextEditor from 'react-rte';

const initialState = {
  metaKeywords: '',
  educationDetailList: [],
  certificationList: [],
  uploadedPhotos: [],
  selectedClinics: [],
  clinicUploadedPhotos: [],
  showActivationKey: true,
  description: ''
}

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      state.practiceType = action.payload.practiceType;
      state.speciality = action.payload.speciality;
      state.fullname = action.payload.fullname;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.confirmPassword = action.payload.confirmPassword;
      state.gender = action.payload.gender;
      state.license = action.payload.license;
      state.phonenumber = action.payload.phonenumber;
      state.activationKey = action.payload.activationKey;
      state.languages = action.payload.languages;
      state.services = action.payload.services;
      state.questionResponse = action.payload.questionResponse;
      state.description = action.payload.description;
      state.metaTitle = action.payload.metaTitle;
      state.metaDesc = action.payload.metaDesc;
      state.metaKeywords = action.payload.metaKeywords;
      state.educationDetailList = action.payload.educationDetailList;
      state.certificationList = action.payload.certificationList;
      state.uploadedPhotos = action.payload.uploadedPhotos;
      state.selectedClinics = action.payload.selectedClinics;
      state.clinicPracticeType = action.payload.clinicPracticeType;
      state.clinicUploadedPhotos = action.payload.clinicUploadedPhotos;
      state.clinicPreviewFile = action.payload.clinicPreviewFile;
      state.clincLogo = action.payload.clincLogo;
      state.clinicName = action.payload.clinicName;
      state.clinicEmail = action.payload.clinicEmail;
      state.clinicPhoneNumber = action.payload.clinicPhoneNumber;
      state.clinicWebsite = action.payload.clinicWebsite;
      state.clinicSpeciality = action.payload.clinicSpeciality;
      state.showActivationKey = action.payload.showActivationKey;
      state.previewFile = action.payload.previewFile;
      state.logo = action.payload.logo;
      state.selectedFeatures = action.payload.selectedFeatures;
      state.clinicLogo = action.payload.clinicLogo;
      state.openToWork = action.payload.openToWork;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateRequest } = providerSlice.actions

export default providerSlice.reducer