import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Step1 } from './pages/provider/step1';
import { Step1 as ClinicStep1 } from './pages/clinic/step1';
import { Step2 } from './pages/provider/step2';
import { Step2 as ClinicStep2 } from './pages/clinic/step2';
import { Step3 } from './pages/provider/step3';
import { Step3 as ClinicStep3 } from './pages/clinic/step3';
import { Step4 } from './pages/provider/step4';
import { Step5 } from './pages/provider/step5';
import { Step6 } from './pages/provider/step6';
import { Step7 } from './pages/provider/step7';
import { Step8 } from './pages/provider/step8';
import { Thankyou } from './pages/provider/thankyou';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Step1></Step1>,
  },
  {
    path: "/step2",
    element: <Step2></Step2>,
  },
  {
    path: "/step3",
    element: <Step3></Step3>,
  },
  {
    path: "/step4",
    element: <Step4></Step4>,
  },
  {
    path: "/step5",
    element: <Step5></Step5>,
  },
  {
    path: "/step6",
    element: <Step6></Step6>,
  },
  {
    path: "/step7",
    element: <Step7></Step7>,
  },
  {
    path: "/step8",
    element: <Step8></Step8>,
  },
  {
    path: "/thankyou",
    element: <Thankyou></Thankyou>,
  },
  {
    path: "/clinic/step1",
    element: <ClinicStep1/>
  },
  {
    path: "/clinic/step2",
    element: <ClinicStep2/>
  },
  {
    path: "/clinic/step3",
    element: <ClinicStep3/>
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
